import { createReactLifecycles } from 'single-spa-workfront'
import RootComponent from './root.jsx'

const lifecycles = createReactLifecycles({
  rootComponent: RootComponent,
  suppressComponentDidCatchWarning: true,
})

export const bootstrap = lifecycles.bootstrap
export const mount = lifecycles.mount
export const unmount = lifecycles.unmount

export default RootComponent
console.log('I was scheduled')